import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select", "output1", "output2"]

  async limitDeliveryMethods() {
    try {
      const selectedValue = this.selectTarget.value
      const response = await fetch(`/karty/typy-kart/${selectedValue}/metody-wysylki.json`);

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const json_response = await response.json()

      // hide
      document.querySelectorAll("#delivery_methods .delivery_method")
              .forEach(elementToHide => {
          elementToHide.classList.add("hidden");
        }
      )
      // show
      if (!json_response.data)
        return

      json_response.data.forEach(elementToShow => {
        document.getElementById(`delivery_method_${elementToShow.id}`).classList.remove("hidden");
      });

    } catch (error) {
      console.error(error)
    }
  }

  async showCardTypeDescription() {
    try {
      document.getElementById('card_type_description').classList.add('hidden')
      document.getElementById('card_type_description_text').innerHTML = ''
      const selectedValue = this.selectTarget.value

      const response = await fetch(`/karty/typy-kart.json`); // Replace with your JSON URL
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const json_response = await response.json();
      let element = json_response.data.find(item => item.id == selectedValue)

      if (element && element.description) {
        document.getElementById('card_type_description').classList.remove('hidden')
        document.getElementById('card_type_description_text').innerHTML = element.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }


    } catch (error) {
      console.error(error);
    }
  }
}
