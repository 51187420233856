// src/controllers/hello_controller.js
import { Controller } from "@hotwired/stimulus"

const RADIO_SELECTION_TOGGLE = 'radio-selection-toggle'

export default class extends Controller {

    static targets = ["modal", "radioButton"]

    connect() {
        document.addEventListener(RADIO_SELECTION_TOGGLE, this.handleSelection.bind(this));
    }

    toggle(event) {
        event.stopImmediatePropagation()

        const selectionEvent = new CustomEvent(RADIO_SELECTION_TOGGLE, {
            detail: {
                name: event.target.name,
                value: event.target.value
            }
        });
        document.dispatchEvent(selectionEvent);
        return false;
    }

    handleSelection(event) {

        var elements = document.getElementsByClassName('new_card_application__delivery_data')
        for (var i=0; i < elements.length; i++) {
          elements[i].classList.add('hidden');
        }
        if (event.detail.name === this.radioButtonTarget.name) {
          document.getElementById('new_card_application__delivery_data_' + event.detail.value)
                  .classList.remove('hidden')
        }
    }
}
