// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"
import "../stylesheets/application"

import { Application } from "stimulus"
import Notification from "stimulus-notification"
import Dropdown from "stimulus-dropdown"

import 'animate.css'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


document.addEventListener('DOMContentLoaded', function() {

  AOS.init({
    startEvent: 'turbolinks:load' // if you are using turbolinks
  });
});



const application = Application.start()
application.register("notification", Notification)
application.register("dropdown", Dropdown)
